import React from 'react';

/* css */
import './TabBasic.css';

/* material-ui */
import ToolTip from '@mui/material/Tooltip';

/* others */
import DataList from '../general/DataList';
import WonDeals from '../dashboard/overview/WonDeals';
import Pipeline from '../dashboard/overview/Pipeline';
import PriceList from '../general/PriceList';
import { AddContact } from '../general/no-options/AddItemComponents';
import DataHandler from '../general/DataHandler';
import TaimerAvatar from '../general/TaimerAvatar';
import TaimerComponent from '../TaimerComponent';
import { getWorktypes } from '../Data';
import ExpensesOverView from '../dashboard/overview/Expenses';
import InvoicingOverView from '../dashboard/overview/Invoicing';
import ActivitiesOverView from '../dashboard/overview/Activities';
import SliderFieldGroup from '../general/SliderFieldGroup';
import { returnCurrencyList } from '../general/Currencies';
import { SettingsContext } from '../SettingsContext';
import { formatInputNumber, isCompanyUsingInvoiceCountryCode, validateVatNumber, formatVatNumber } from '../helpers';
import { withSnackbar } from 'notistack';
import { ReactComponent as StructureIcon } from '../projects/images/subproject.svg';
import { ReactComponent as AssociatedIcon } from './images/hierarchy.svg';
import { ReactComponent as UserIcon } from '../navigation/ActionIcons/my_profile.svg';
import CustomFields from '../projects/customfields/CustomFields';
import CustomFieldsPreview from '../projects/customfields/CustomFieldsPreview';
import Utils from './../general/Utils.js';
import CoreDialog from '../dialogs/mass_operations/CoreDialog';

import _, { cloneDeep, uniqBy } from 'lodash';
import colors from '../colors';

import styles from './TabBasic.module.scss';
import cardStyles from '../general/styles/CardStyles.module.scss';
import StatusTag from '../general/StatusTag';
import Skeleton from 'react-loading-skeleton';
import ToggleableContainerList from '../general/ToggleableContainerList';
import AddTag from '../general/no-options/AddTag';
import { Delete, Visibility, Groups2 as TeamIcon } from '@mui/icons-material';
import UserAvatarRow from '../general/UserAvatarRow';
import moment from 'moment';
import Slider from '../general/Slider';
import VersionContentManager from '../general/VersionContentManager';

const currencyList = returnCurrencyList();
const currencySymbolsMap = currencyList.reduce((acc, cl) => ({ ...acc, [cl.id]: cl.symbol }), {});

class TabBasic extends TaimerComponent {
    static contextType = SettingsContext;
    static defaultProps = { showLockedUsersWithTag: true };

    trimmable = ['name'];

    constructor(props, context) {
        super(props, context, 'accounts/TabBasic');

        this.showAccountNameInHeader = false;

        this.detailsSections = [
            {
                key: 'details',
                title: this.tr('Account details'),
                initiallyOpen: true,
            },
            {
                key: 'invoicing_details',
                title: this.tr('Invoicing details'),
                initiallyOpen: true,
            },
            {
                key: 'addresses',
                title: this.tr('Addresses'),
                tabs: [
                    {
                        id: 'invoicing_addresses',
                        label: this.tr('Invoicing'),
                    },
                    {
                        id: 'visiting_addresses',
                        label: this.tr('Visiting'),
                    },
                    {
                        id: 'delivery_addresses',
                        label: this.tr('Delivery'),
                    },
                ],
            },
            {
                key: 'team',
                title: this.tr('Team'),
            },
            {
                key: 'contacts',
                title: this.tr('Contacts'),
                tabs: [
                    {
                        id: 'account_contacts',
                        label: this.tr('Account contacts'),
                    },
                    {
                        id: 'partners',
                        label: this.tr('Partners'),
                    },
                ],
            },
            {
                key: 'hourly_rates',
                title: this.tr('Hourly rates'),
                hidden: () => !this.props.checkPrivilege('customers', 'customer_pricelist_read', this.props.company),
                tooltip:
                    this.tr('You can specify hourly rates for users & job types in Settings, Account card & Project Card.') +
                    '\r\n' +
                    this.tr('Account card rates override settings rates.') +
                    '\r\n\r\n' +
                    this.tr('Additionally, user based rates override Jobtype rates.'),
            },
        ];

        this.visitingAddressFields = [
            {
                title: this.tr('Company'),
                key: 'name',
            },
            {
                title: this.tr('VAT no.'),
                key: 'vatid',
            },
            {
                title: this.tr('Address'),
                key: 'address',
            },
            ...(this.context.taimerAccount.showState
                ? [
                      {
                          title: this.tr('City'),
                          key: 'city',
                      },
                      {
                          title: this.tr('State'),
                          key: 'state',
                      },
                      {
                          title: this.tr('Zip'),
                          key: 'postalcode',
                      },
                  ]
                : [
                      {
                          title: this.tr('Zip'),
                          key: 'postalcode',
                      },
                      {
                          title: this.tr('City'),
                          key: 'city',
                      },
                  ]),
            {
                title: this.tr('Country'),
                key: 'country',
            },
        ];

        this.deliveryAddressFields = [
            {
                title: this.tr('Company'),
                key: 'name',
            },
            {
                title: this.tr('VAT no.'),
                key: 'vatid',
            },
            {
                title: this.tr('Address'),
                key: 'address',
            },
            ...(this.context.taimerAccount.showState
                ? [
                      {
                          title: this.tr('City'),
                          key: 'city',
                      },
                      {
                          title: this.tr('State'),
                          key: 'state',
                      },
                      {
                          title: this.tr('Zip'),
                          key: 'postalcode',
                      },
                  ]
                : [
                      {
                          title: this.tr('Zip'),
                          key: 'postalcode',
                      },
                      {
                          title: this.tr('City'),
                          key: 'city',
                      },
                  ]),
            {
                title: this.tr('Country'),
                key: 'country',
            },
            {
                title: this.tr('Contact person'),
                key: 'contact',
            },
        ];

        this.refPriceList = React.createRef();

        this.state = {
            contacts: [],
            partners: [],
            employees: [],
            worktypes: [],
            account_types: [],
            customership_groups: [],
            enterprise_groups: [],
            pricelists: [],
            activePricelist: false,
            defaultPrices: {
                users: {},
                worktypes: {},
            },
            displayEnterpriseGroups: true,
            catalog: {
                catalog_only: false,
                catalog_value: { label: '', name: '', id: '0' },
            },
            catalogs: [],
            invoicingSettings: {},
            countryCode: "",
            teaminfo: 'team_info',
            companyCurrencies: [],
            billingLanguages: [{ label: this.tr('English'), value: 'en', id: 'en' }],
            quoteJobtypePool: [],
            tagPoolSettingsData: {
                use_separate_tag_pools: 0,
                create_tags_only_from_settings: 1,
            },
            tagpool: [],
            allowedCountryCodes: [],
            allCountryCodes: [],
            vatErrorMessage: "",
            teams: []
        }

        this.reverseChargeFields = [
            { id: 0, label: this.tr('No reverse charge') },
            { id: 1, label: this.tr('Reverse charge applied') },
        ];

        this.translations = {
            locked: this.tr('locked'),
            freelancer: this.tr('freelancer'),
        };
    }

    addTeamMember = (item) => {
        const team_members = _.cloneDeep(this.props.account.team_members);
        team_members.push({ ...item, new: 1 }); // should be unshift but order isn't saved in backend
        this.props.valueChanged({ team_members });
    };

    editTeamMember = (item) => {
        const { id: customer, company, enqueueSnackbar } = this.props;
        const data = {
            fill_in: item.fill_in,
            fill_in_phone: item.fill_in_phone,
            role_description: item.role_description,
            fill_in_starts: item.fill_in_starts,
            fill_in_ends: item.fill_in_ends,
            customers_id: customer,
            id: item.id,
            users_id: item.users_id,
            company,
        };
        DataHandler.post({ url: `accounts/user_team_details` }, data)
            .done(() => {
                enqueueSnackbar(this.tr('Changes saved successfully!'), {
                    variant: 'success',
                });
                this.props.updateData && this.props.updateData();
            })
            .catch(() => {
                enqueueSnackbar(this.tr('Saving changes failed!'), {
                    variant: 'error',
                });
            });
    };

    rmTeamMember = (member) => {
        const team_members = _.cloneDeep(this.props.account.team_members);
        const foundIndex = team_members.findIndex((e) => e.id === member);
        if (foundIndex != -1) {
            team_members[foundIndex].deleted = 1;
        }
        this.props.valueChanged({ team_members });
    };

    addContact = (contact) => {
        if (!contact.id) return;

        const { contacts: old } = this.props.account;
        contact.added = 1;
        const contacts = old.concat([contact]);
        contacts.sort(function (a, b) {
            const nameA = a.lastname.toUpperCase();
            const nameB = b.lastname.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });

        this.props.valueChanged({ contacts });
    }

    isCompanyUsingCountryCode = () => {
        const { addons } = this.context;
        return isCompanyUsingInvoiceCountryCode(addons, this.props.company);
    }

    addPartner = (partner) => {
        if (!partner.id) return;

        const { partners: old } = this.props.account;

        const partners = old.concat([partner]);
        partner.added = 1;
        partners.sort(function (a, b) {
            const nameA = a.lastname.toUpperCase();
            const nameB = b.lastname.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });

        this.props.valueChanged({ partners });
    };

    rmContact = (contact) => {
        const contacts = this.props.account.contacts;
        contacts[contacts.findIndex((e) => e.id === contact)].deleted = 1;
        this.props.valueChanged({ contacts });
    };

    rmPartner = (partner) => {
        const partners = this.props.account.partners;
        partners[partners.findIndex((e) => e.id === partner)].deleted = 1;
        this.props.valueChanged({ partners });
    };

    getCountryCodes = () => {
        const { company } = this.props;

        DataHandler.get({ url: `subjects/vat_codes/${company}`}).done(vatcodes => {
            const allowedCountryCodes = [];
            const addedCodes = {};
            vatcodes.forEach(v => {
                if (v.country_code && !addedCodes[v.country_code]) {
                    allowedCountryCodes.push({ label: v.country_code, name: v.country_code, id: v.country_code, value: v.country_code });
                    addedCodes[v.country_code] = 1;
                }
            });
            this.setState({ allowedCountryCodes })
        });
        DataHandler.get({ url: `settings/autocomplete`, country_codes: 1 }).done(settings => {
            this.setState({ allCountryCodes: settings.country_codes })
        });
    }

    updateComponentData = async () => {
        const { company } = this.props;

        this.getTagPool();
        this.getTagSettings();

        if (this.isCompanyUsingCountryCode()) {
            this.getCountryCodes();
        }

        DataHandler.get({ url: `subjects/employees/${company}`, dontIgnoreCompany: true, frTransl: this.tr('Freelancer') }).done((response) => this.setState({ employees: response }));

        DataHandler.get({ url: `subjects/employees/${company}`, dontIgnoreCompany: true, frTransl: this.tr('Freelancer'), locked: '1' }).done((response) =>
            this.setState({ lockedEmployees: response })
        );

        DataHandler.get({ url: `subjects/account_entities/vatid+customer_company+parent_id` }).done((accounts) => {
            const mainUnits = accounts
                .filter((el) => el.companies_id == 0)
                .map((el) => {
                    el.label = el.name;
                    return el;
                })
                .sort((a, b) => {
                    return a.label.localeCompare(b.label);
                });
            this.setState({ accounts: accounts, mainUnits: mainUnits });
        });

        DataHandler.get({ url: `subjects/contacts` }).done((response) => this.setState({ contacts: response }));

        DataHandler.get({ url: `subjects/employees/${company}`, dontIgnoreCompany: true }).done((response) => {
            const userTagProps = {
                fields: { label: 'label' },
                showLocked: this.props.showLockedUsersWithTag,
                transl: this.translations,
            };            
            response.forEach((p, i) => {
                response[i] = { ...Utils.showLockedAndFreelancerUserTag(p, userTagProps) };
            });
            this.setState({ employees: response });
        });

        DataHandler.get({ url: `subjects/employees/${company}`, dontIgnoreCompany: true, locked: '1' }).done((response) => {
            const userTagProps = {
                fields: { label: 'label' },
                showLocked: this.props.showLockedUsersWithTag,
                transl: this.translations,
            };            
            response.forEach((p, i) => {
                response[i] = { ...Utils.showLockedAndFreelancerUserTag(p, userTagProps) };
            });
            this.setState({ lockedEmployees: response });
        });

        DataHandler.get({ url: `subjects/partners` }).done((response) => this.setState({ partners: response }));

        DataHandler.get({ url: `accounts/invoicing_settings/${company}` }).done((invoicingSettings) => this.setState({ invoicingSettings }));

        DataHandler.get({ url: `subjects/customership_groups/${company}` }).done((response) => {
            this.setState({ customership_groups: response });
        });

        DataHandler.get({ url: `subjects/enterprise_groups/${company}` }).done((response) => {
            response.length > 0 ? this.setState({ enterprise_groups: response, displayEnterpriseGroups: true }) : this.setState({ displayEnterpriseGroups: false });
        });

        DataHandler.get({ url: `subjects/account_types` }).done((account_types) => this.setState({ account_types }));

        DataHandler.post({ url: `products/company/${company}/catalog` }, { all: true })
            .done((response) => {
                if (response.status == 'ok' && response.catalogs) this.setState({ catalogs: response.catalogs });
            })
            .fail((err) => console.error(err));

        const companyData = this.props.companies.find((c) => c.id == this.props.company);

        DataHandler.get({ url: `invoices/currency_rates`, company: company }).done((currencies) => {
            const activeCurrencies = currencies.filter((cc) => cc.active_status == 'active');
            if (activeCurrencies?.length > 0)
                this.setState({
                    companyCurrencies: [...activeCurrencies.map((c) => ({ rateId: c.id, id: c.currency_label, name: c.currency_label, label: c.currency_label, rate: c.currency_rate }))],
                });
            else {
                this.setState({
                    companyCurrencies: [
                        {
                            id: companyData.currency,
                            name: companyData.currency,
                            label: companyData.currency,
                            rate: 1,
                            symbol: currencySymbolsMap[companyData.currency],
                        },
                    ],
                });
            }
        });

        DataHandler.get({ url: `subjects/user_groups/all`, type: "teams" }).done((response) => {
            this.setState({ teams: response });
        });

        this.getCustomerCatalog();

        this.getCatalogSettings();

        getWorktypes(company).then((worktypes) => this.setState({ worktypes }));

        this.updatePricelists();
        this.updateBillingLanguages();
        this.getQuoteJobtypePool(company);
    }

    getTagPool = () => {
        DataHandler.get({ url: `tags/${this.props.company}`, type: 'account' }).done((data) => {
            this.setState({ tagpool: data.tags });
        });
    };

    getTagSettings = () => {
        DataHandler.get({ url: `settings/company/${this.props.company}/tagSettings` })
            .done((response) => {
                if (response.tagPoolSettingsData) this.setState({ tagPoolSettingsData: response.tagPoolSettingsData });
            })
            .fail((response) => {
                console.log('Error', response);
            });
    };

    getQuoteJobtypePool = async (company) => {
        // Can't check the permission here as the customers permission data is loaded when company is changed (and takes time)
        // thus, the permission to get this data is only checked in backend.
        if (!this.context.addons.nav) return;
        try {
            const quoteJobtypePool = await DataHandler.get({ url: `settings/company/${company}/quote_jobtypes`, customers_id: this.props.account.id });
            this.setState({ quoteJobtypePool });
        } catch (e) {
            console.error(e);
            this.setState({ quoteJobtypePool: [] });
        }
    };

    getCustomerCatalog = () => {
        const { id, company } = this.props;

        if (id && company) {
            DataHandler.post({ url: `products/company/${company}/catalog` }, { customer: id })
                .done((response) => {
                    if (response.catalogs && response.catalogs.length > 0) {
                        const catalog = {
                            ...this.state.catalog,
                            catalog_value: { label: response.catalogs[0].name, id: response.catalogs[0].id },
                        };
                        this.setState({ catalog });
                    }
                })
                .fail((err) => console.error(err));
        }
    };

    updatePricelists = (refresh = false) => {
        const { id, company } = this.props;

        DataHandler.get({ url: `accounts/defaultprices/${company}` }).done((defaultPrices) => {
            this.setState({ defaultPrices });
        });

        id &&
            DataHandler.get({ url: `accounts/${id}/pricelists/${company}`, ...(refresh ? { refresh: 1 } : {}) }).done((pricelists) => {
                let { selectedPricelist } = this.state;

                const activePricelist = pricelists.find((x) => x.active === '1') || { id: 0, value: 0, label: this.tr('Not selected'), name: this.tr('Not selected') };

                if (!selectedPricelist && activePricelist) {
                    selectedPricelist = activePricelist.id;
                } else if (!selectedPricelist && pricelists.length) {
                    selectedPricelist = pricelists[0].id;
                }

                this.setState({ pricelists, selectedPricelist, activePricelist });
            });
    };

    componentDidMount() {
        super.componentDidMount();
        this.updateComponentData();
        this.getCountryCode();
    }

    componentDidUpdate(prevProps) {
        if (this.props.company !== prevProps.company) {
            this.updateComponentData();
            this.getCountryCode();
            this.setState({ selectedPricelist: 0 }, this.updatePricelists);
            this.getCustomerCatalog();
            this.getCatalogSettings();
        }

        if (this.props.companies !== prevProps.companies) {
            this.updateBillingLanguages();
        }
    }

    updateBillingLanguages = () => {
        const companyData = this.props.companies.find((c) => c.id == this.props.company);
        const billingLanguages = companyData
            ? companyData.print_languages.map((pl) => {
                  return { ...pl, label: this.tr(pl.label) };
              })
            : this.state.billingLanguages;

        this.setState({ billingLanguages });
    };

    getCountryCode = () => {
        const { company } = this.props;
        DataHandler.get({ url: `subjects/companies`, country_code: true }).done((companies) => {
            const countryCode = companies.find((c) => c.id == company).country_code;
            this.setState({ countryCode: countryCode });
        });
    };

    /* gets called when form value changes */
    onChange = (evt) => {
        const { enqueueSnackbar, id, company, account, accounts } = this.props;
        const { name, value } = evt.target;
        let update = {};
        let duplicates = 0;

        if (evt.target.name === 'name') {
            const mainUnit = this.props.accounts.find((a) => a.id === account.main_unit);
            duplicates = accounts.find(
                (e) =>
                    e.name.toLowerCase() == evt.target.value.toLowerCase() &&
                    id != e.id &&
                    (!mainUnit || (mainUnit && mainUnit.label.toLowerCase() !== evt.target.value.toLowerCase())) &&
                    id != e.parent_id
            );
            if (id && duplicates) {
                enqueueSnackbar(duplicates.name + this.tr(' already exists.'), {
                    variant: 'info',
                });
                return;
            } else if (!id && duplicates) {
                enqueueSnackbar(duplicates.name + this.tr(' already exists.'), {
                    variant: 'info',
                });
                return;
            }
        }

        if (evt.target.name === 'talenom_id') {
            const talenomid = evt.target.value;

            if (isNaN(evt.target.value)) {
                enqueueSnackbar(talenomid + this.tr(' is not a number.'), {
                    variant: 'error',
                });
                return;
            } else {
                //Checks is Talenom id already in use
                DataHandler.get({ url: `accounts/${id}/checkTalenomId/${company}`, talenomid: talenomid }).done((result) => {
                    if (result && !result.isAvailable) {
                        enqueueSnackbar(this.tr('Talenom id ') + '"' + talenomid + '"' + this.tr(' already exists.'), {
                            variant: 'error',
                        });

                        return;
                    }
                });
            }
        }

        //Checks is eFina Id a number
        if (evt.target.name === 'efina_id') {
            const eFinaId = evt.target.value;

            //Lets check if value is not a number
            if (isNaN(evt.target.value)) {
                enqueueSnackbar(eFinaId + this.tr(' is not a number.'), {
                    variant: 'error',
                });

                return;
            } else {
                //If efina id is available lets continue. If its not...
                DataHandler.get({ url: `accounts/${id}/checkEfinaId`, eFinaId: eFinaId, company: this.props.company }).done((result) => {
                    if (result && !result.isAvailable) {
                        enqueueSnackbar(this.tr('Efina id ') + '"' + eFinaId + '"' + this.tr(' already exists.'), {
                            variant: 'error',
                        });

                        return;
                    }
                });
            }
        }

        if (evt.target.name === 'product_catalog') {
        }

        update = { [name]: this.trimmable.indexOf(name) !== -1 ? value.trim() : value };

        if (name === 'customers_id') update.customer = accounts.find((e) => e.id === value).name;

        this.props.valueChanged(update);
    }

    validateVat = (address) => {
        if (this.isCompanyUsingNav()) {
            const check = validateVatNumber(formatVatNumber(address.vatid), address.country_code);
            if (check?.error) {
                const vatErrorMessage = !address.vatid ? this.tr("VAT no. is required") : (check?.format ? this.tr('VAT no. is not in correct format (e.g. ${exampleFormat})', {exampleFormat: check?.format}) : this.tr("VAT no. is not in correct format (begin with correct countrycode and have only numbers or letters)"));
                this.setState({ vatErrorMessage })
                return false;
            }
        } else if (false) {
            const isValid = address.vatid && String(address.vatid).length > 0;

            this.setState({ vatErrorMessage: !isValid ? this.tr("VAT no. is required") : "" });

            return isValid;
        }
        
        this.setState({ vatErrorMessage: "" })
        return true;
    }

    changeGroup = (item) => {
        this.props.valueChanged({ customership_groups_id: item.id });
    };

    changeAccountManager = (item) => {
        this.props.valueChanged({ account_manager: item.id });
    };

    importPricesFromCompany = (pricelistId) => {
        DataHandler.post({ url: `accounts/pricelists/${pricelistId}/default_prices` }).then((data) => {
            this.refPriceList.current && this.refPriceList.current.fetchData(true);
        });
    };

    getCatalogSettings = () => {
        const { catalog } = this.state;
        const { id, company } = this.props;
        DataHandler.get({ url: `accounts/${id}/company/${company}/catalogonly` }, { catalog_only: catalog.catalog_only })
            .done((result) => {
                this.setState({
                    catalog: {
                        ...this.state.catalog,
                        catalog_only: result.catalog_only,
                    },
                });
            })
            .fail((response) => {
                console.error(response);
            });
    };

    //<-- custom account number />

    isCompanyUsingMeritAktiva = () => {
        const { addons } = this.context;
        return addons.meritaktiva && addons.meritaktiva.used_by_companies.indexOf(this.props.company) > -1;
    };

    isCompanyUsingNav = () => {
        const { addons } = this.context;
        return (addons.nav && addons.nav.used_by_companies.indexOf(this.props.company) > -1);
    }

    getStatusTagData = () => {
        const { account } = this.props;

        if (account.locked == 1) {
            const color = colors.faded_red;
            const text = this.tr('Archived');
            return { color, text };
        } else {
            const color = colors.greenish_cyan;
            const text = this.tr('Active');
            return { color, text };
        }
    };

    renderDetailsTopSection = () => {
        const { id, account, companies, company, onCompanyChanged, editable } = this.props;
        const { employees, lockedEmployees, customership_groups } = this.state;
        const {
            addons,
            taimerAccount: { isMulticompany },
        } = this.context;

        const selectedCship = customership_groups.find((e) => e.id === account.customership_groups_id);

        let employeesForAccountManager = [];
        if (!isMulticompany) {
            employeesForAccountManager =
                employees && this.state.lockedEmployees
                    ? [
                          { id: '0', users_id: '0', name: this.tr('No account manager'), label: this.tr('No account manager'), deleted: '0', noAccountManager: true },
                          ...lockedEmployees
                              .filter((u) => u.id == account.account_manager)
                              .map((u) => {
                                  const user = { ...u };
                                  user.name = user.label = user.name + ' (' + this.tr('Locked user') + ')';
                                  return user;
                              }),
                          ...employees,
                      ]
                    : [];
        }

        employeesForAccountManager = employeesForAccountManager.filter((e) => [this.props.company, '0'].includes(e.companies_id) || e.noAccountManager);
        const statusTagData = this.getStatusTagData();

        const contacts = account.contacts?.filter((c) => c.deleted != 1) || [];
        const partners = account.partners?.filter((c) => c.deleted != 1) || [];

        return (
            <div className={cardStyles.detailsTopSection}>
                {!!id && (
                    <div className={cardStyles.topRow}>
                        <div className={cardStyles.tags}>
                            <StatusTag text={statusTagData.text} color={statusTagData.color} />
                        </div>
                        <div className={cardStyles.buttons}>
                            {isMulticompany && (
                                <ToolTip classes={{ tooltip: 'darkblue-tooltip' }} title={''}>
                                    <button onClick={() => this.props.onOpenSlider(this.tr('Account Relations'), 'relations')}>
                                        <AssociatedIcon />
                                        {this.tr('Account Relations')}
                                    </button>
                                </ToolTip>
                            )}
                            {!VersionContentManager.isFeatureHidden(this.namespace, 'accountStructure') && <ToolTip classes={{ tooltip: 'darkblue-tooltip' }} title={''}>
                                <button onClick={() => this.props.onOpenSlider(this.tr('Account Structure'), 'account_structure')}>
                                    <StructureIcon />
                                    {this.tr('Account Structure')}
                                </button>
                            </ToolTip>}
                        </div>
                    </div>
                )}
                <div className={`${cardStyles.titles} ${!this.props.id ? cardStyles.noTopMargin : ''}`}>
                    <h1>
                        {!id ? (
                            this.tr('New account')
                        ) : account.name ? (
                            `${account.name} (${
                                addons.custom_customer_id && addons.custom_customer_id.used_by_companies.indexOf(company) > -1 && account.customer_id ? account.customer_id : account.id
                            })`
                        ) : (
                            <Skeleton />
                        )}
                    </h1>
                    {!!id && account.parent_status != 4 && account.vatid && <p>{account.vatid}</p>}
                </div>
                <div className={cardStyles.keyInfo}>
                    {isMulticompany && (
                        <div>
                            <p>{this.tr('View as company')}:</p>
                            <DataList
                                placeholder={this.tr('Select company')}
                                variant="standard"
                                classNamePrefix="gura"
                                className="seller"
                                value={companies.find((c) => c.id == company)}
                                options={companies}
                                onChange={(company) => onCompanyChanged(company.id)}
                                isDisabled={account.parent_status == 4} // Don't allow viewing subunits as other companies.
                            />
                        </div>
                    )}
                    <div>
                        <p>{this.tr('Account type')}:</p>
                        <p>{account.types.map((t) => t.name).join(', ')}</p>
                    </div>
                    {!isMulticompany && (
                        <div>
                            <p>{this.tr('Account manager')}:</p>
                            <DataList
                                placeholder={this.tr('Select account manager')}
                                variant="standard"
                                classNamePrefix="gura"
                                className="seller"
                                value={employeesForAccountManager.find((e) => e.id == account.account_manager) || ''}
                                options={employeesForAccountManager}
                                onChange={this.changeAccountManager}
                                isDisabled={!editable}
                            />
                        </div>
                    )}
                    {(customership_groups.filter((g) => g.deleted != 1).length > 0 || selectedCship) && (
                        <div>
                            <p>{this.tr('Account Group')}:</p>
                            <DataList
                                placeholder={this.tr('Select account group')}
                                variant="standard"
                                classNamePrefix="gura"
                                className="seller"
                                value={selectedCship}
                                options={customership_groups.filter((g) => g.deleted != 1)}
                                onChange={this.changeGroup}
                                isDisabled={!editable}
                            />
                        </div>
                    )}
                    <div className={cardStyles.peoplePreview}>
                        <div className={cardStyles.contactPreview}>
                            <p>{this.tr('Team members')}:</p>
                            <UserAvatarRow
                                useAvatars
                                tooltip={(items) => {
                                    const showItems = items.slice(0, 10);
                                    const rest = items.length - showItems.length;
                                    return (
                                        <div className={cardStyles.contactPreviewLabels}>
                                            {showItems.length == 0 ? <p>{this.tr('Click to add team members')}</p> : showItems.map((c) => <p>{`${c.label}${c.title ? `, ${c.title}` : ''}`}</p>)}
                                            {rest > 0 && <p>+{this.tr('${amount} others', { amount: rest })}</p>}
                                            <button onClick={this.showTeamMemberSlider}>{showItems.length == 0 ? this.tr('Add team members') : this.tr('View team members')}</button>
                                        </div>
                                    );
                                }}
                                onClick={this.showTeamMemberSlider}
                                items={this.getAddedTeamMembers()}
                            />
                        </div>
                        <div className={cardStyles.contactPreview}>
                            <p>{this.tr('Contacts')}:</p>
                            <UserAvatarRow
                                tooltip={(items) => {
                                    const showItems = items.slice(0, 10);
                                    const rest = items.length - showItems.length;
                                    return (
                                        <div className={cardStyles.contactPreviewLabels}>
                                            {showItems.length == 0 ? <p>{this.tr('Click to add contacts')}</p> : showItems.map((c) => <p>{`${c.label}${c.title ? `, ${c.title}` : ''}`}</p>)}
                                            {rest > 0 && <p>+{this.tr('${amount} others', { amount: rest })}</p>}
                                            <button onClick={this.showContactSlider}>{showItems.length == 0 ? this.tr('Add contacts') : this.tr('View contacts')}</button>
                                        </div>
                                    );
                                }}
                                onClick={this.showContactSlider}
                                items={uniqBy([...contacts, ...partners], 'id')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    getInvoicingAddressFields = () => {
        const { company } = this.props;
        const companyData = this.props.companies.find((c) => c.id == company) || {};
        return [
            {
                title: this.tr('Company'),
                key: 'name',
            },
            {
                title: this.tr('VAT no.'),
                key: 'vatid',
                checkValidity: (address) => this.validateVat(address),
                required: this.isCompanyUsingNav(),
                errorMessage: this.tr(this.state.vatErrorMessage)
            },
            ...((this.context.addons.fortnox && this.context.addons.fortnox.used_by_companies.indexOf(this.props.company) > -1) ||
            (this.context.addons.meritaktiva && this.context.addons.meritaktiva.used_by_companies.indexOf(this.props.company) > -1)
                ? [
                      {
                          title: this.tr('VAT no. 2'),
                          key: 'vatid_2',
                          validation: 'vatid2',
                      },
                  ]
                : []),
            {
                title: this.tr('Address'),
                key: 'address',
            },
            ...(this.context.addons.fortnox && this.context.addons.fortnox.used_by_companies.indexOf(this.props.company) > -1
                ? [
                      {
                          title: this.tr('Address 2'),
                          key: 'address_2',
                      },
                  ]
                : []),
            ...(this.context.taimerAccount.showState
                ? [
                      {
                          title: this.tr('City'),
                          key: 'city',
                      },
                      {
                          title: this.tr('State'),
                          key: 'state',
                      },
                      {
                          title: this.tr('Zip'),
                          key: 'postalcode',
                      },
                  ]
                : [
                      {
                          title: this.tr('Zip'),
                          key: 'postalcode',
                      },
                      {
                          title: this.tr('City'),
                          key: 'city',
                      },
                  ]),
            {
                title: this.tr('Country'),
                key: 'country',
            },
            {
                title: this.tr('Contact person'),
                key: 'contact',
            },
            {
                title: this.tr('E-invoice operator'),
                key: 'e_operator',
            },
            {
                title: this.tr('E-invoice address'),
                key: 'e_address',
            },
            {
                title: this.tr('Invoicing email'),
                key: 'email',
                validation: this.isCompanyUsingMeritAktiva() ? 'emailMultiple' : 'email',
            },
            ...(this.state.billingLanguages.length > 1
                ? [
                      {
                          key: 'bill_language',
                          title: this.tr('Invoice language'),
                          type: 'data_select',
                          options: this.state.billingLanguages,
                          defaultValue: companyData?.print_lang,
                          addNoneOption: false,
                      },
                  ]
                : []),
            ...(this.context.addons.nav == undefined
                ? [
                      {
                          key: 'reverse_charge',
                          title: this.tr('Reverse charge'),
                          type: 'data_select',
                          options: this.reverseChargeFields,
                          defaultValue: '0',
                          addNoneOption: false,
                      },
                  ]
                : []),
            ...(this.context.addons.invoice_currency
                ? [
                      {
                          key: 'currency',
                          title: this.tr('Invoice currency'),
                          type: 'data_select',
                          options: this.state.companyCurrencies,
                          defaultValue: companyData?.currency,
                          addNoneOption: false,
                      },
                  ]
                : []),
            ...(this.isCompanyUsingCountryCode() ? [{
                    key: 'country_code',
                    title: this.tr('Country code'),
                    type: 'data_select',
                    options: this.state.allowedCountryCodes,
                    allOptions: this.state.allCountryCodes,
                    addNoneOption: false,
                    required: true
                }] : []),  
        ];
    };

    closeTeamMemberSlider = () => this.setState({ teamMemberSliderVisible: false });
    showTeamMemberSlider = () => this.setState({ teamMemberSliderVisible: true });

    closeContactSlider = () => this.setState({ contactSliderVisible: false });
    showContactSlider = () => this.setState({ contactSliderVisible: true });

    getAddedTeamMembers = () => {
        const { account } = this.props;
        const { employees } = this.state;

        const teamMembersWithTags = account.team_members?.filter((c) => c.deleted != 1 && c.type != "team")
            .map(t => ({ ...t, label: `${t.label}${employees.find(e => e.id == t.users_id)?.companies_id > 0 ? '' : ` (${this.translations.freelancer})`}${employees.find(e => e.id == t.users_id)?.locked > 0 ? ` (${this.translations.locked})` : ''}` }))

        const addedTeams = account.team_members?.filter((c) => c.deleted != 1 && c.type == "team")
            .map(t => {
                t.oneRow = true;
                return t;
            }) || [];

        (teamMembersWithTags || []).push(...addedTeams);

        return teamMembersWithTags;
    }

    getTeamOptions = () => {
        const { teams } = this.state;
        const { account, company } = this.props;

        const { employees } = this.state;
        const filteredTeamMembers = employees?.filter((e) => account.team_members.find((ee) => ee.users_id === e.users_id) === undefined);

        const filteredTeams = teams.filter((e) => account.team_members.find((ee) => ee.id === "t_" + e.id) === undefined);

        const allowedTeams = filteredTeams.filter(t => t.companies_id == company);

        const users = filteredTeamMembers.map(u => {
            const id = "u_" + u.id;
            return {
                ...u,
                id: id,
                value: id,
                users_id: u.id,
                headerName: "user",
                type: "user",
            };
        });

        const groups = allowedTeams.map(g => {
            const id = "t_" + g.id;
            return {
                id: id,
                value: id,
                label: g.name,
                name: g.name,
                headerName: "team",
                type: "team",
            };
        });

        const options =
            [
                { icon: TeamIcon, type: "header", label: this.tr('Teams'), name: 'team' },
                ...groups,
                { icon: UserIcon, type: "header", label: this.tr('Users'), name: 'user' },
                ...users
            ];

        return options;
    }

    renderTeam = (showAll = false) => {
        const { editable, company } = this.props;
        const teamOptions = this.getTeamOptions();

        return (
            <div className="tab-content" key="people-default">
                <SliderFieldGroup
                    canDeleteItem={() => true}
                    editingDisabled={!editable}
                    onItemSaved={this.editTeamMember}
                    onItemAdded={this.addTeamMember}
                    onItemDeleted={(item) => this.rmTeamMember(item.id)}
                    items={/*account.team_members?.filter((c) => c.deleted != 1)*/this.getAddedTeamMembers()}
                    newItemDefaultValues={{ ...teamOptions[0], isNew: true }}
                    renderRightComponentForItem={(item) => 
                        item.type == "team"
                            ? <TeamIcon className={styles.teamListTeamIcon}/>
                            : <TaimerAvatar name={item.label} color={item.color} id={item.users_id} />
                    }                    
                    allowAdd={editable}
                    showAll={showAll}
                    addFromDropdownItems={teamOptions}
                    addFromDropdownModeHasHeaders={true}
                    sliderEditTitle={this.tr('Edit member details')}
                    getOptions={(teamMember) =>
                        editable
                            ? [ 
                                ...(teamMember.type == "team" 
                                    ? []
                                    : [{
                                      key: 'view',
                                      title: this.tr('View user'),
                                      onClick: (item) => {
                                          this.closeTeamMemberSlider();
                                          this.context.functions.updateView({
                                              module: 'users',
                                              action: 'view',
                                              id: item.users_id,
                                              company,
                                          });
                                      },
                                      icon: <Visibility />,
                                  }]),
                                ...(teamMember.type == "team" 
                                  ? []
                                  : [{
                                      key: 'edit',
                                      title: this.tr('Edit'),
                                      isEdit: true,
                                  }]),
                                  {
                                      key: 'remove',
                                      title: this.tr('Remove'),
                                      isDelete: true,
                                  },
                              ]
                            : []
                    }
                    fields={[
                        {
                            key: 'label',
                            title: this.tr('Name'),
                            disabled: true,
                            isHidden: (item) => item.type == "team"
                        },
                        {
                            key: 'label',
                            title: this.tr('Team'),
                            disabled: true,
                            isHidden: (item) => item.type != "team"
                        },
                        {
                            key: 'email',
                            title: this.tr('Email'),
                            disabled: true,
                            formURL: (value) => `mailto:${value}`,
                            isHidden: (item) => item.type == "team"
                        },
                        {
                            key: 'phone',
                            title: this.tr('Phone'),
                            disabled: true,
                            formURL: (value) => `tel:${value}`,
                            isHidden: (item) => item.type == "team"
                        },
                        {
                            key: 'role',
                            title: this.tr('Title'),
                            type: 'text',
                            disabled: true,
                            isHidden: (item) => item.type == "team"
                        },
                        {
                            key: 'edited_by_name',
                            title: this.tr('Edited by'),
                            type: 'text',
                            disabled: true,
                            isHidden: (item) => item.type == "team"
                        },
                        {
                            key: 'edited',
                            title: this.tr('Last edited'),
                            type: 'date',
                            disabled: true,
                            isHidden: (item, isPreview) => item.type == "team" || (isPreview && item.edited == '0000-00-00'),
                        },
                        {
                            key: 'role_description',
                            title: this.tr('Role specification'),
                            type: 'text',
                            isHidden: (item) => item.type == "team"
                        },
                        {
                            key: 'fill_in',
                            title: this.tr('Suppleant'),
                            type: 'data_select',
                            options: this.state.employees,
                            addNoneOption: true,
                            setOtherValuesWithSelection: (item, selectedValue) => {
                                return {
                                    fill_in_phone: selectedValue.phone,
                                    fill_in_starts:
                                        item.fill_in_starts && item.fill_in_starts != '0000-00-00' ? item.fill_in_starts : moment().startOf('month').subtract(10, 'months').format('YYYY-MM-DD'),
                                    fill_in_ends: item.fill_in_ends && item.fill_in_ends != '0000-00-00' ? item.fill_in_ends : moment().startOf('month').add(1, 'months').format('YYYY-MM-DD'),
                                };
                            },
                            isHidden: (item) => item.type == "team"
                        },
                        {
                            key: 'fill_in_phone',
                            title: this.tr('Suppleant phone'),
                            type: 'text',
                            isHidden: (item) => item.type == "team" || !item.fill_in,
                        },
                        {
                            key: 'suppliant_daterange',
                            startKey: 'fill_in_starts',
                            endKey: 'fill_in_ends',
                            title: this.tr('Suppleant timespan'),
                            type: 'daterange',
                            isHidden: (item) => item.type == "team" || !item.fill_in,
                        },
                    ]}
                />
            </div>
        );
    };

    renderContacts = (showAll = false) => {
        const { contacts } = this.state;
        const { account, editable, company } = this.props;
        const filteredContacts = contacts?.filter((e) => account.contacts && account.contacts.find && account.contacts.find((ee) => ee.id === e.id) === undefined);
        return (
            <div className="tab-content" key="people-contacts">
                <SliderFieldGroup
                    onItemAdded={this.addContact}
                    editingDisabled
                    onItemDeleted={(item) => this.rmContact(item.id)}
                    canDeleteItem={(_, isPreview) => editable && isPreview}
                    items={account.contacts?.filter((c) => c.deleted != 1)}
                    newItemDefaultValues={filteredContacts[0]}
                    renderRightComponentForItem={(contact) => <TaimerAvatar className={styles.contactAvatar} key={contact.id} name={contact.label} noavatar title="" />}
                    showAll={showAll}
                    allowAdd={editable}
                    addFromDropdownItems={filteredContacts}
                    addDropdownNoOptions={AddContact}
                    addDropdownNoOptionsProps={{ companies_id: company, customers_id: account.id, contact_type: '1' }}
                    getOptions={() =>
                        editable
                            ? [
                                  ...(this.context.functions.checkPrivilege('persons', 'read', company)
                                      ? [
                                            {
                                                key: 'view',
                                                title: this.tr('View contact'),
                                                onClick: (item) => {
                                                    this.closeContactSlider();
                                                    this.context.functions.updateView({ module: 'contact', action: 'view', id: item.id, companies_id: company });
                                                },
                                                icon: <Visibility />,
                                            },
                                        ]
                                      : []),
                                  {
                                      key: 'remove',
                                      title: this.tr('Remove'),
                                      isDelete: true,
                                      icon: <Delete />,
                                  },
                              ]
                            : []
                    }
                    fields={[
                        {
                            key: 'label',
                            title: this.tr('Name'),
                            disabled: true,
                        },
                        {
                            key: 'title',
                            title: this.tr('Title'),
                            disabled: true,
                        },
                        {
                            key: 'phone',
                            title: this.tr('Phone'),
                            validation: 'phone',
                            disabled: true,
                        },
                        {
                            key: 'email',
                            title: this.tr('Email'),
                            validation: 'email',
                            disabled: true,
                        },
                    ]}
                />
            </div>
        );
    };

    renderPartners = (showAll = false) => {
        const { partners } = this.state;
        const { account, editable, company } = this.props;
        const filteredPartners = partners?.filter((e) => (account.partners || []).find((ee) => ee.id === e.id) === undefined);
        return (
            <div className="tab-content" key="people-partners">
                <SliderFieldGroup
                    editingDisabled
                    onItemDeleted={(item) => this.rmPartner(item.id)}
                    canDeleteItem={() => editable}
                    onItemAdded={this.addPartner}
                    items={account.partners?.filter((c) => c.deleted != 1)}
                    newItemDefaultValues={filteredPartners[0]}
                    renderRightComponentForItem={(contact) => <TaimerAvatar className={styles.contactAvatar} key={contact.id} name={contact.label} noavatar title="" />}
                    showAll={showAll}
                    allowAdd
                    addFromDropdownItems={filteredPartners}
                    addDropdownNoOptions={AddContact}
                    addDropdownNoOptionsProps={{ companies_id: company, customers_id: account.id, contact_type: '2' }}
                    getOptions={() =>
                        editable
                            ? [
                                  ...(this.context.functions.checkPrivilege('persons', 'read', company)
                                      ? [
                                            {
                                                key: 'view',
                                                title: this.tr('View contact'),
                                                onClick: (item) => this.context.functions.updateView({ module: 'contact', action: 'view', id: item.id, companies_id: company }),
                                                icon: <Visibility />,
                                            },
                                        ]
                                      : []),
                                  {
                                      key: 'remove',
                                      title: this.tr('Remove'),
                                      isDelete: true,
                                      icon: <Delete />,
                                  },
                              ]
                            : []
                    }
                    fields={[
                        {
                            key: 'label',
                            title: this.tr('Name'),
                            disabled: true,
                        },
                        {
                            key: 'title',
                            title: this.tr('Title'),
                            disabled: true,
                        },
                        {
                            key: 'phone',
                            title: this.tr('Phone'),
                            validation: 'phone',
                            disabled: true,
                        },
                        {
                            key: 'email',
                            title: this.tr('Email'),
                            validation: 'email',
                            disabled: true,
                        },
                    ]}
                />
            </div>
        );
    };

    onAccountDetailsEdited = (details) => {
        const { id, company } = this.props;
        let formattedDetails = {
            ...details,
        };
        if (details.tags) {
            formattedDetails = {
                ...formattedDetails,
                tags: (details.tags || []).map((t) => (typeof t == 'string' ? t : t.label)),
            };
        }
        if (details.quote_jobtypes) {
            formattedDetails = {
                ...formattedDetails,
                quote_jobtypes: (details.quote_jobtypes || []).map((jt) => (typeof jt == 'object' ? jt.id : jt)),
            };
        }
        if (this.props.account?.parental_company > 0 && details.main_unit != this.props.account?.main_unit) {
            this.props.enqueueSnackbar(this.tr('Please note that changing main unit will clear parental company field.'), {
                variant: 'info',
            });
        }
        if (this.props.account.main_unit > 0 && details.parental_company != this.props.account?.parental_company) {
            this.props.enqueueSnackbar(this.tr('Please note that changing parental company will clear main unit field.'), {
                variant: 'info',
            });
        }
        if (formattedDetails.catalog) {
            const catalog = formattedDetails.catalog;
            const catalog_only = catalog.id == 0 ? '0' : formattedDetails.catalog_only;
            this.setState({
                catalog: {
                    ...this.state.catalog,
                    catalog_only,
                    catalog_value: catalog,
                },
            });
            DataHandler.put({ url: `products/company/${company}/catalog` }, { catalogids: catalog.id == 0 ? null : [catalog.id], customerids: [id], archiverelations: catalog.id == 0 })
                .done(() => {
                    setTimeout(() => {
                        this.getCustomerCatalog();
                    }, 1000);
                })
                .fail((err) => {
                    console.error(err);
                });
            if (formattedDetails.catalog_only != undefined) {
                DataHandler.put({ url: `accounts/${id}/company/${company}/catalogonly` }, { catalog_only })
                    .done(() => {
                        setTimeout(() => {
                            this.getCatalogSettings();
                        }, 1000);
                    })
                    .fail((response) => {
                        console.error(response);
                    });
            }
        }
        this.props.valueChanged(formattedDetails);
        if (details.tags) {
            this.getTagPool();
        }
    };

    checkCustomFieldsValidity = (item) => {
        const { customFields } = this.props;

        const customFieldsErrors = {};
        const erroredFields = [];

        _.forEach(customFields, (v) => {
            const value = (item.custom && item.custom[v.id]) || '';
            if (v.required && v.show_in_details && value === '') {
                customFieldsErrors[v.id] = true;
                erroredFields.push(v.name);
            }
        });

        return {customFieldsErrors, erroredFields}; 
    }

    customValidation = (item  = null) => {
        const { customFieldsErrors, erroredFields } = this.checkCustomFieldsValidity(item ?? this.account);
        const valid = Object.values(customFieldsErrors).indexOf(true) == -1;

        if (!valid) {
            this.props.enqueueSnackbar(this.tr('Required fields not filled: ${fields}!', {
                fields: erroredFields.join(', '),
            }), {
                variant: 'error',
            });
        }

        return valid;
    }

    renderContentForDetailsSection = (section, showAll = false) => {
        const {
            taimerAccount: { isMulticompany },
            userObject,
            addons,
        } = this.context;
        const { account, id, editable, checkPrivilege, company, customFields, customFieldsErrors, mainUnits } = this.props;
        const { employees, account_types, pricelists, activePricelist, customership_groups, enterprise_groups, worktypes, defaultPrices, tagpool } = this.state;
        const { defaulttermsofpayment, defaultannotation, defaultpenaltyinterest } = this.state.invoicingSettings;
        const accountManager = account.account_manager;
        const hideCompanySpecificFields = this.context.taimerAccount.preventCompanySpecificAutocompleteData && userObject.companies_id != company && !checkPrivilege('customers', 'read', company);

        let employeesForAccountManager = [];
        if (!isMulticompany) {
            employeesForAccountManager =
                employees && this.state.lockedEmployees
                    ? [
                          { id: '0', users_id: '0', name: this.tr('No account manager'), label: this.tr('No account manager'), deleted: '0', noAccountManager: true },
                          ...this.state.lockedEmployees
                              .filter((u) => u.id == accountManager)
                              .map((u) => {
                                  const user = { ...u };
                                  user.name = user.label = user.name + ' (' + this.tr('Locked user') + ')';
                                  return user;
                              }),
                          ...employees,
                      ]
                    : [];
        }

        switch (section) {
            case 'details':
                return (
                    <>
                        <SliderFieldGroup
                            items={[account]}
                            editButtonLocation="top"
                            onItemSaved={this.onAccountDetailsEdited}
                            sliderEditTitle={this.tr('Edit account details')}
                            editingDisabled={!editable}
                            additionalValidations={this.customValidation}
                            additionalFields={(item, onFieldChanged) => (
                                <CustomFields
                                    fields={customFields}
                                    header={this.tr('Additional information')}
                                    form={0}
                                    values={item?.custom || {}}
                                    onChange={(key, value) => {
                                        const values = {
                                            custom: {
                                                ...item.custom,
                                                [key.replace('custom_', '')]: value,
                                            },
                                        };
                                        onFieldChanged(values);
                                    }}
                                    errors={(item && this.checkCustomFieldsValidity(item).customFieldsErrors) ?? {}}
                                />
                            )}
                            fields={[
                                {
                                    key: 'name',
                                    title: this.tr('Account name'),
                                    required: true,
                                },
                                {
                                    key: 'customer_id',
                                    title: this.tr('Customer number'),
                                    required: addons.custom_customer_id && addons.custom_customer_id.used_by_companies.indexOf(company) > -1,
                                    isHidden: () => !(addons.custom_customer_id && addons.custom_customer_id.used_by_companies.indexOf(company) > -1),
                                },
                                {
                                    key: 'vatid',
                                    title: this.tr('Business ID'),
                                    isHidden: () => this.props.account?.parent_status == 4,
                                },
                                {
                                    key: 'main_unit',
                                    title: this.tr('Main unit'),
                                    type: 'data_select',
                                    options: mainUnits,
                                    isHidden: () => !(checkPrivilege('customers', 'unit_read') && this.props.account?.parent_status == 4),
                                    disabled: !checkPrivilege('customers', 'unit_write'),
                                },
                                {
                                    key: 'parental_company',
                                    title: this.tr('Parental company'),
                                    type: 'data_select',
                                    options: mainUnits,
                                    isHidden: () => {
                                        return false;
                                        const selectedParentalCompany = (this.props.mainUnits || []).find((e) => e.id === this.props.account?.parental_company);
                                        return this.props.account?.parent_status == 4 || !(this.context.versionId >= 3 && selectedParentalCompany);
                                    },
                                    disabled: !checkPrivilege('customers', 'corporate_structure_write'),
                                },
                                {
                                    key: 'types',
                                    title: this.tr('Account type'),
                                    type: 'select',
                                    isMulti: true,
                                    options: account_types.map(at => ({ ...at, value: at.id })),
                                    required: true,
                                    isHidden: () => isMulticompany,
                                },
                                {
                                    key: 'branchofbusiness',
                                    title: this.tr('Branch of business'),
                                },
                                {
                                    key: 'telephone',
                                    title: this.tr('Company phone'),
                                    validation: 'phone',
                                    formURL: (value) => `tel:${value}`,
                                },
                                {
                                    key: 'email',
                                    title: this.tr('Company email'),
                                    validation: 'email',
                                    formURL: (value) => `mailto:${value}`,
                                },
                                {
                                    key: 'www',
                                    title: this.tr('Company website'),
                                    formURL: (value) => (value.includes('http') ? `${value}` : `http://${value}`),
                                    openInNewTab: true,
                                },
                                {
                                    key: 'tags',
                                    title: this.tr('Tags'),
                                    type: 'select',
                                    isMulti: true,
                                    options: tagpool.map((t) => ({ ...t, value: t.id, label: t.name })),
                                    formatMultiValue: (value, options) => options.find((o) => (value.id ? o.id == value.id : o.tag == value)),
                                    noOptions: AddTag,
                                    additionalProps: {
                                        company,
                                        tagType: 1,
                                    },
                                },
                                {
                                    key: 'resourcing_note',
                                    title: this.tr('Resourcing Note'),
                                    isHidden: () => !(this.context.addons && this.context.addons.resourcing),
                                    additionalProps: {
                                        multiline: true,
                                    },
                                },
                                {
                                    key: 'account_manager',
                                    title: this.tr('Account manager'),
                                    type: 'data_select',
                                    options: employeesForAccountManager,
                                    isHidden: () => isMulticompany,
                                },
                                {
                                    key: 'customership_groups_id',
                                    title: this.tr('Account Group'),
                                    type: 'data_select',
                                    options: customership_groups.filter((g) => g.deleted != 1),
                                    isHidden: () => hideCompanySpecificFields,
                                    hideIfEmpty: !this.context.taimerAccount.forceCustomerShipGroup,
                                    required: this.context.taimerAccount.forceCustomerShipGroup,
                                },
                                {
                                    key: 'enterprise_groups_id',
                                    title: this.tr('Enterprise Group'),
                                    type: 'data_select',
                                    options: enterprise_groups.filter((g) => g.deleted != 1),
                                    addNoneOption: true,
                                    isHidden: () => !(this.state.displayEnterpriseGroups && !hideCompanySpecificFields),
                                },
                                {
                                    key: 'quickbooks_id',
                                    title: this.tr('Quickbooks Id'),
                                    isHidden: () => !addons?.quickbooks,
                                    disabled: true,
                                },
                                {
                                    key: 'efina_id',
                                    title: this.tr('eFina Account id'),
                                    isHidden: () => !addons?.efina,
                                },
                                // {
                                //     key: 'efina_target_id',
                                //     title: this.tr('eFina Target id'),
                                //     isHidden: () => !addons?.efina,
                                // },
                                {
                                    key: 'talenom_id',
                                    title: this.tr('Talenom id'),
                                    isHidden: () => !addons?.talenom,
                                },
                                {
                                    key: 'netvisor_id',
                                    title: this.tr('Netvisor ID'),
                                    isHidden: () => !addons?.netvisor,
                                },
                                {
                                    key: 'fivaldi_id',
                                    title: this.tr('Fivaldi ID'),
                                    isHidden: () => !addons?.fivaldi,
                                },
                                {
                                    key: 'wintime_id',
                                    title: this.tr('Wintime ID'),
                                    isHidden: () => !addons?.wintime,
                                },
                                {
                                    key: 'nav_id',
                                    overrideValueKey: 'wintime_id',
                                    title: this.tr('NAV ID'),
                                    isHidden: () => !addons?.nav,
                                },
                                {
                                    key: 'emce_id',
                                    title: this.tr('EmCe ID'),
                                    isHidden: () => !addons?.emce,
                                },
                                {
                                    key: 'integration_id',
                                    title: this.tr('Integration ID'),
                                    isHidden: () => !addons?.fennoa,
                                },
                                {
                                    key: 'venda',
                                    overrideValueKey: 'integration_id',
                                    title: this.tr('Venda ID'),
                                    isHidden: () => !addons?.venda,
                                },
                                {
                                    key: 'corporation_object',
                                    overrideValueKey: 'integration_id',
                                    title: this.tr('Corporation object'),
                                    isHidden: () => !addons?.nav,
                                },
                                {
                                    key: 'pe_id',
                                    overrideValueKey: 'integration_id',
                                    title: this.tr('PE ID'),
                                    isHidden: () => !(this.context.taimerAccount && this.context.taimerAccount.hasS3SweFinance == 1),
                                },
                                {
                                    key: 'supplier_integration_id',
                                    title: this.tr('Supplier corporation object'),
                                    isHidden: () => !addons?.nav,
                                },
                                {
                                    key: 'meritaktiva',
                                    overrideValueKey: 'accounting_integration_id',
                                    title: this.tr('Merit Aktiva ID'),
                                    isHidden: () => (this.context.addons?.meritaktiva?.used_by_companies || []).indexOf(this.props.company) == -1,
                                    disabled: true,
                                },
                                {
                                    key: 'fortnox',
                                    overrideValueKey: 'accounting_integration_id',
                                    title: this.tr('Fortnox ID'),
                                    isHidden: () => (this.context.addons?.fortnox?.used_by_companies || []).indexOf(this.props.company) == -1,
                                },
                                {
                                    key: 'heeros',
                                    overrideValueKey: 'accounting_integration_id',
                                    title: this.tr('Heeros ID'),
                                    isHidden: () => (this.context.addons?.heeros?.used_by_companies || []).indexOf(this.props.company) == -1,
                                },
                                {
                                    key: 'heeros_supplier',
                                    overrideValueKey: 'supplier_integration_id',
                                    title: this.tr('Heeros supplier ID'),
                                    isHidden: () => (this.context.addons?.heeros?.used_by_companies || []).indexOf(this.props.company) == -1,
                                },
                                {
                                    key: 'gothia',
                                    overrideValueKey: 'accounting_integration_id',
                                    title: this.tr('Gothia ID'),
                                    isHidden: () => (this.context.addons?.gothia?.used_by_companies || []).indexOf(this.props.company) == -1,
                                },
                                {
                                    key: 'datev',
                                    overrideValueKey: 'accounting_integration_id',
                                    title: this.tr('DATEV ID'),
                                    isHidden: () => (this.context.addons?.datev?.used_by_companies || []).indexOf(this.props.company) == -1,
                                },
                                {
                                    key: 'navcsv',
                                    overrideValueKey: 'accounting_integration_id',
                                    title: this.tr('NAV ID'),
                                    isHidden: () => (this.context.addons?.navcsv?.used_by_companies || []).indexOf(this.props.company) == -1,
                                },
                                {
                                    key: 'tietotili',
                                    overrideValueKey: 'accounting_integration_id',
                                    title: this.tr('TietotiliOnline ID'),
                                    isHidden: () => (this.context.addons?.tietotili?.used_by_companies || []).indexOf(this.props.company) == -1,
                                },
                            ]}
                        />
                        <CustomFieldsPreview fields={customFields} values={account.custom} form={0} />
                    </>
                );
            case 'invoicing_details':
                return (
                    <div className="tab-invoicing" key="tab-invoicing">
                        <SliderFieldGroup
                            items={[{ ...account, catalog: this.state.catalog?.catalog_value, catalog_only: this.state.catalog?.catalog_only }]}
                            editButtonLocation="top"
                            onItemSaved={this.onAccountDetailsEdited}
                            sliderEditTitle={this.tr('Edit invoicing details')}
                            editingDisabled={!editable}
                            testKey={section}
                            fields={[
                                {
                                    key: 'defaultpenaltyinterest',
                                    title: this.tr('Penalty Interest'),
                                    placeholder: defaultpenaltyinterest,
                                    formatValue: (value) => formatInputNumber(value),
                                    validation: 'numeric',
                                    additionalProps: {
                                        maximumFractionDigits: 2,
                                    },
                                },
                                {
                                    key: 'defaultannotation',
                                    title: this.tr('Notice Period (days)'),
                                    placeholder: defaultannotation,
                                    validation: 'numeric',
                                    additionalProps: {
                                        maximumFractionDigits: 0,
                                    },
                                },
                                {
                                    key: 'defaulttermsofpayment',
                                    title: this.tr('Terms of Payment (days)'),
                                    placeholder: defaulttermsofpayment,
                                    validation: 'numeric',
                                    additionalProps: {
                                        maximumFractionDigits: 0,
                                    },
                                },
                                {
                                    key: 'catalog_only',
                                    title: this.tr('use catalogue products only'),
                                    type: 'switch',
                                    isHidden: (item, isPreview) => isPreview || VersionContentManager.isFeatureHidden(this.namespace, 'catalogs'),
                                },
                                {
                                    key: 'catalog',
                                    title: this.tr('Product catalog'),
                                    type: 'select',
                                    options: this.state.catalogs,
                                    addNoneOption: true,
                                    isHidden: (item) => item.catalog_only != 1 || VersionContentManager.isFeatureHidden(this.namespace, 'catalogs'),
                                },
                                {
                                    key: 'quote_jobtypes',
                                    title: this.tr('Quote jobtypes'),
                                    type: 'select',
                                    isMulti: true,
                                    options: this.state.quoteJobtypePool,
                                    isHidden: () => !(this.context.addons.nav && checkPrivilege('customers', 'set_quote_jobtypes', company)),
                                    formatMultiValue: (value, options) => options.find((o) => (value.id ? o.id == value.id : o.id == value)),
                                    additionalProps: {
                                        getOptionValue: (option) => option.id,
                                    },
                                },
                            ]}
                        />
                    </div>
                );
            case 'hourly_rates':
                return (
                    <div className="tab-invoicing">
                        <PriceList
                            needsUpgrade={VersionContentManager.isFeatureUpgradeTrigger(this.namespace, 'hourlyRates')}
                            ref={this.refPriceList}
                            editable={checkPrivilege('customers', 'customer_pricelist_write')}
                            entity="accounts"
                            currency={this.props.currency}
                            baseUrl={`accounts/pricelists`}
                            createNewUrl={`accounts/${id}/pricelists/${company}`}
                            employees={employees}
                            worktypes={worktypes}
                            protitles={_.values(defaultPrices.protitles)}
                            defaultPrices={defaultPrices}
                            activePricelist={activePricelist}
                            pricelists={pricelists}
                            onImportPrices={(pricelist) => this.importPricesFromCompany(pricelist.id)}
                            importPricesLabel={this.tr('Import Default Prices')}
                            afterSave={this.updatePricelists}
                        />
                    </div>
                );
            case 'invoicing_addresses':
                return (
                    <div className="tab-content" key="details-visiting-address">
                        <SliderFieldGroup
                            key="invoicing_addresses"
                            editingDisabled={!editable}
                            items={account.invoicing_addresses}
                            newItemDefaultValues={{ name: account.name, vatid: account.vatid }}
                            canDeleteItem={(item) => item.deleted < 1}
                            fields={this.getInvoicingAddressFields()}
                            onItemSaved={(address) => this.onSaveAddress(address, 'invoicing_addresses')}
                            onItemDeleted={(address) => this.onDeleteAddress(address, 'invoicing_addresses')}
                            onCloseSlider={() => this.setState({ vatErrorMessage: "" })}
                            alwaysShowDelete
                            allowAdd
                            deleteDisabledTooltip={this.tr("This address can't be deleted, because it is linked to a project.")}
                            sliderEditTitle={this.tr('Edit invoicing address')}
                            sliderAddTitle={this.tr('Add invoicing address')}
                            getOptions={() =>
                                editable
                                    ? [
                                          {
                                              key: 'edit',
                                              title: this.tr('Edit'),
                                              isEdit: true,
                                          },
                                          {
                                              key: 'remove',
                                              title: this.tr('Remove'),
                                              isDelete: true,
                                          },
                                      ]
                                    : []
                            }
                        />
                    </div>
                );
            case 'delivery_addresses':
                return (
                    <div className="tab-content" key="details-visiting-address">
                        <SliderFieldGroup
                            key="delivery_addresses"
                            editingDisabled={!editable}
                            items={account.delivery_addresses}
                            fields={this.deliveryAddressFields}
                            newItemDefaultValues={{ name: account.name, vatid: account.vatid }}
                            canDeleteItem={(item) => item.is_linked < 1}
                            onItemSaved={(address) => this.onSaveAddress(address, 'delivery_addresses')}
                            onItemDeleted={(address) => this.onDeleteAddress(address, 'delivery_addresses')}
                            alwaysShowDelete
                            deleteDisabledTooltip={this.tr("This address can't be deleted, because it is linked to a project.")}
                            allowAdd
                            sliderEditTitle={this.tr('Edit delivery address')}
                            sliderAddTitle={this.tr('Add delivery address')}
                            getOptions={() =>
                                editable
                                    ? [
                                          {
                                              key: 'edit',
                                              title: this.tr('Edit'),
                                              isEdit: true,
                                          },
                                          {
                                              key: 'remove',
                                              title: this.tr('Remove'),
                                              isDelete: true,
                                          },
                                      ]
                                    : []
                            }
                        />
                    </div>
                );
            case 'visiting_addresses':
                return (
                    <div className="tab-content" key="details-visiting-address">
                        <SliderFieldGroup
                            key="visiting_addresses"
                            editingDisabled={!editable}
                            items={account.visiting_addresses}
                            fields={this.visitingAddressFields}
                            newItemDefaultValues={{ name: account.name, vatid: account.vatid }}
                            canDeleteItem={(item) => Number(item.is_customer_default) != 1}
                            onDefaultChanged={(e, itemId) => this.onDefaultAddressChanged(e.target.checked, 'visiting_addresses', itemId, 'is_customer_default')}
                            onItemSaved={(address) => this.onSaveAddress(address, 'visiting_addresses', 'is_customer_default')}
                            onItemDeleted={(address) => this.onDeleteAddress(address, 'visiting_addresses')}
                            defaultSelectionKey="is_customer_default"
                            alwaysShowDelete
                            deleteDisabledTooltip={this.tr("This address can't be deleted, because it is the default visiting address.")}
                            allowAdd
                            sliderEditTitle={this.tr('Edit visiting address')}
                            sliderAddTitle={this.tr('Add visiting address')}
                            getOptions={() =>
                                editable
                                    ? [
                                          {
                                              key: 'edit',
                                              title: this.tr('Edit'),
                                              isEdit: true,
                                          },
                                          {
                                              key: 'remove',
                                              title: this.tr('Remove'),
                                              isDelete: true,
                                          },
                                      ]
                                    : []
                            }
                        />
                    </div>
                );
            case 'team':
                return this.renderTeam(showAll);
            case 'account_contacts':
                return this.renderContacts(showAll);
            case 'partners':
                return this.renderPartners(showAll);
            default:
                return null;
        }
    };

    onDetailsScroll = (e) => {
        if (!this.props.id) return;
        if (e.currentTarget.scrollTop >= 100 && !this.showAccountNameInHeader) {
            this.showAccountNameInHeader = true;
            this.props.setShowAccountNameInHeader(true);
        } else if (e.currentTarget.scrollTop < 100 && this.showAccountNameInHeader) {
            this.showAccountNameInHeader = false;
            this.props.setShowAccountNameInHeader(false);
        }
    };

    onDeleteAddress = (item, type) => {
        const account = cloneDeep(this.props.account);
        const items = account[type] || [];
        const itemToEditIndex = items.findIndex((i) => i.id == item.id);
        /*if (itemToEditIndex != -1) {
            items[itemToEditIndex].deleted = 1;
        }
        this.props.valueChanged({ [type]: items });*/

        if (item.is_linked > 0) {
            this.context.functions.showDialog(
                <CoreDialog
                    onDialogClose={this.context.functions.closeDialog}
                    onDialogSave={this.onEditQuote}
                    dialogType={'delete'}
                    dialogProps={{
                        wider: true,
                        onCloseClick: this.context.functions.closeDialog,
                        open: true,
                        close: this.context.functions.closeDialog,
                        confirmButtonClass: 'red',
                        confirmDisabled: false,
                        header: this.tr('Address is linked to a project'),
                        translatedConfirmButtonText: this.tr('Delete'),
                        warning: () => <p>{this.tr('Do you want to delete the address?')}</p>,
                        onConfirm: () => {
                            this.context.functions.closeDialog();
                            if (itemToEditIndex != -1) {
                                items[itemToEditIndex].deleted = 1;
                            }
                            this.props.valueChanged({ [type]: items });
                        },
                    }}
                />
            );
        } else {
            if (itemToEditIndex != -1) {
                items[itemToEditIndex].deleted = 1;
            }
            this.props.valueChanged({ [type]: items });
        }

    };

    onSaveAddress = (item, type, defaultSelectionKey) => {
        const account = cloneDeep(this.props.account);
        const items = account[type] || [];
        item.vatid = formatVatNumber(item.vatid);
        if (defaultSelectionKey && Number(item[defaultSelectionKey]) > 0) {
            items.forEach((item) => {
                if (defaultSelectionKey) item[defaultSelectionKey] = 0;
            });
        }
        const itemToEditIndex = items.findIndex((i) => i.id == item.id);
        if (itemToEditIndex != -1) {
            items[itemToEditIndex] = item;
        } else if (item.id == -1) {
            item.deleted = 0;
            item.is_linked = 0;
            items.push(item);
        }
        this.props.valueChanged({ [type]: items });
    };

    onDefaultAddressChanged = (checked, key, itemId, defaultSelectionKey) => {
        const account = cloneDeep(this.props.account);
        const items = account[key] || [];
        if (defaultSelectionKey && checked) {
            items.forEach((item) => {
                if (defaultSelectionKey) item[defaultSelectionKey] = 0;
            });
        }
        const itemToEditIndex = items.findIndex((i) => i.id == itemId);
        if (itemToEditIndex != -1) {
            items[itemToEditIndex] = {
                ...items[itemToEditIndex],
                [defaultSelectionKey]: checked ? 1 : 0,
            };
        }
        this.props.valueChanged({ [key]: items });
    };

    render() {
        const { account, checkPrivilege, hasProjectPrivilege, company } = this.props;

        return (
            <div className="main-wrapper">
                <div className={`${cardStyles.detailsBox} ${cardStyles.withDoubleTabs}`} onScroll={this.onDetailsScroll}>
                    {this.renderDetailsTopSection()}
                    <div className={styles.detailsSections}>
                        <ToggleableContainerList sections={this.detailsSections} renderContentForSection={this.renderContentForDetailsSection} />
                    </div>
                </div>
                <div className={`${cardStyles.mainContent} ${cardStyles.withDoubleTabs} ${styles.statistics}`}>
                    {checkPrivilege('customers', 'customer_crm_read') && <ActivitiesOverView customersId={account.id === 'unsaved' ? -1 : account.id} company={company} />}
                    {hasProjectPrivilege('read', company) && !VersionContentManager.isFeatureHidden(this.namespace, 'pipelines') && <Pipeline customersId={account.id === 'unsaved' ? -1 : account.id} company={company} currency={this.props.currency} />}
                    {hasProjectPrivilege('read', company) && <WonDeals customersId={account.id === 'unsaved' ? -1 : account.id} company={company} currency={this.props.currency} />}
                    {this.context.functions.checkPrivilegeAny('invoices', undefined, company) && (
                        <InvoicingOverView customersId={account.id === 'unsaved' ? -1 : account.id} company={company} currency={this.props.currency} />
                    )}
                    {this.context.functions.checkPrivilegeAny('worktrips', undefined, company) && (
                        <ExpensesOverView customersId={account.id === 'unsaved' ? -1 : account.id} company={company} currency={this.props.currency} />
                    )}
                </div>
                <Slider title={this.tr('Contacts associated with account')} open={this.state.contactSliderVisible} onClose={this.closeContactSlider}>
                    <div className={cardStyles.contactSliderContainer}>
                        {
                            <ToggleableContainerList
                                sections={[
                                    {
                                        key: 'contacts',
                                        title: this.tr('Contacts'),
                                        initiallyOpen: true,
                                        onlyContent: true,
                                        tabs: [
                                            {
                                                id: 'account_contacts',
                                                label: this.tr('Account contacts'),
                                            },
                                            {
                                                id: 'partners',
                                                label: this.tr('Partners'),
                                            },
                                        ],
                                    },
                                ]}
                                renderContentForSection={(section) => this.renderContentForDetailsSection(section, true)}
                            />
                        }
                    </div>
                </Slider>
                <Slider title={this.tr('Account team members')} open={this.state.teamMemberSliderVisible} onClose={this.closeTeamMemberSlider}>
                    <div className={cardStyles.contactSliderContainer}>
                        {
                            <ToggleableContainerList
                                sections={[
                                    {
                                        key: 'team',
                                        title: this.tr('Team'),
                                        initiallyOpen: true,
                                        onlyContent: true,
                                    },
                                ]}
                                renderContentForSection={(section) => this.renderContentForDetailsSection(section, true)}
                            />
                        }
                    </div>
                </Slider>
            </div>
        );
    }
}

export default withSnackbar(TabBasic);
